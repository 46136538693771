import React from "react";
import {
  TransitionHistory,
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
  CustomButton,
  FallBackBtn,
  Image,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import Modal from "react-modal";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import CustomTable from "../../components/customTable";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CreateCollectionModal from "../../screens/collection/createCollection";
import { ro } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import CollectionCampaignInfluencerTable from "../../modal/collectionCampaignInfluencer";

const CollectionInfluencerCampaignList = (props) => {
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteCollection, setDeleteCollection] = useState({});
  const [collectionList, setCollectionList] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);
  const [collectionId, setCollectionId] = useState("");
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);

  const navigate = useNavigate();
  const campaignId = props.campaignId;
  const campaignDetail = props?.campaignDetail;
  console.log("campaignDetail", props);

  const platformId =
    campaignDetail?.content_type_deep_data &&
    campaignDetail?.content_type_deep_data[0]?.platform_data?.platform_id;
  useEffect(() => {
    getCollectionList();
  }, []);

  const redux_dispatch = useDispatch();

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function updateProgress(status) {
    setLoading(status);
  }

  function getCollectionList() {
    const params = {
      campaign_id: campaignId,
      org_id: brand_data.org_id,
    };
    Core.getCollectionList(
      getCollectionListSuccess,
      getCollectionListFailed,
      updateProgress,
      params
    );
  }

  function getCollectionListSuccess(response_data) {
    setCollectionList(response_data.collection_list);
  }

  function getCollectionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function postCollectionDelete() {
    const params = {
      collection_id: collectionId,
    };
    Core.postCollectionDelete(
      postCollectionDeleteSuccess,
      postCollectionDeleteFailed,
      (status) => setDeleteCollection({ [collectionId]: status }),
      params
    );
  }

  function postCollectionDeleteSuccess(response_data) {
    getCollectionList();
    toast.success("Collection Deleted Successfully");
    closeDelete();
  }

  function postCollectionDeleteFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function collectionInfluencerPage(collection_id, collection_name) {
    console.log("collection_id", collection_id);

    navigate(`/collection-influencer/${collection_id}`);
  }

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)",
        color: "var(--gray-gray-300)",
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  function openDelete() {
    if (modalIsOpenDelete == false) {
      setModalIsOpenDelete(true);
    } else {
      setModalIsOpenDelete(false);
    }
  }

  function closeDelete() {
    setModalIsOpenDelete(false);
  }

  function openModal() {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const tableSchema = [
    {
      name: "Name",
      selector: (row) => row["collection_name"],
      cell: (data) => <span>{data.collection_name}</span>,
    },
    {
      name: "Date",
      selector: (row) => Utils.convertUnixToDate(row.date_added),
    },

    {
      name: "Action",
      selector: (row) => row.collection_id,
      width: "200px",
      cell: (data) => (
        <span>
          <CustomButton
            onClick={() => {
              openDelete();
              setCollectionId(data.collection_id);
              setCollectionName(data.collection_name);
            }}
            fontSize={"font_size_large"}
            // text={"Delete"}
            iconName="deleteNewIcon"
            isLoading={deleteCollection[data.collection_id]}
            borderRadius={4}
            padding={{ top: 6, right: 18, bottom: 6, left: 18 }}
            textColor={"text_color_light"}
            background={"none"}
            border={"none"}
            boxShadow={"none"}
            hoverColor={"none"}
          />
        </span>
      ),
    },
  ];

  function ExpandedComponentData(item) {
    return (
      <CollectionCampaignInfluencerTable
        collectionId={item.data.collection_id}
        platformId={platformId}
      />
    );
  }

  if (!props.isActive) {
    return <></>;
  }

  return (
    <div style={{ minHeight: 500 }}>
      <div className="bid-list-dropdown-header-container-new">
        {loading ? (
          <Loader />
        ) : (
          <>
            {collectionList.length !== 0 ? (
              <div style={{ width: "100%" }} className="custom-table-bid">
                <CustomTable
                  className="CustomTable"
                  tableSchema={tableSchema}
                  tableData={collectionList}
                  paginationPerPage={15}
                  rowPaddingTop={16}
                  rowPaddingBottom={16}
                  expandableRowsComponent={(item) =>
                    ExpandedComponentData(item)
                  }
                  expandableRows
                  // onRowClicked={(data) =>
                  //   collectionInfluencerPage(
                  //     data.collection_id,
                  //     data.collection_name
                  //   )
                  // }
                  // progressPending={loading}
                  customStyles={customStyles}
                  // paginationServer
                  // paginationTotalRows={next}
                  // onChangeRowsPerPage={getTransactionHistoryList}
                  // onChangePage={handlePageChange}
                  pagination
                />
              </div>
            ) : (
              <div
                style={{ width: "100%" }}
                className="fall-back-center-campaign"
              >
                <FallBackBtn
                  heading={"Oops!! you don't have any collection"}
                  title={
                    "You can add your shortlisted influencers in collections, so that you can share them with anyone and get feedback"
                  }
                  onClick={() => openModal()}
                  text={"Create Collection"}
                />
              </div>
            )}
          </>
        )}
      </div>
      {modalIsOpen && (
        <CreateCollectionModal
          closeModal={closeModal}
          getCollectionList={getCollectionList}
          campaignId={campaignId}
        />
      )}
      <Modal
        isOpen={modalIsOpenDelete}
        onRequestClose={closeDelete}
        style={CancelReminder}
      >
        <>
          <div className="contract-cancellation-confirm">
            <h2 className="cancel-text">Delete Collection</h2>
            <p className="cancel-request">
              Are you sure you want to delete{" "}
              <span style={{ fontWeight: "bold" }}>{collectionName}</span>?
            </p>

            <div className="btn-accept-reject">
              <div className="confirm-cancelliation">
                <>
                  <PrimaryCta
                    third
                    onClick={() => postCollectionDelete()}
                    fontSize={"font_size_regular"}
                    className="create-campaign"
                    text={"Delete"}
                    isLoading={deleteCollection[collectionId]}
                  />

                  <button
                    className="confirm-btn-bid  m-l-32"
                    onClick={() => closeDelete()}
                  >
                    Cancel
                  </button>
                </>
              </div>
              <ToastContainer />
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default CollectionInfluencerCampaignList;

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "200px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
