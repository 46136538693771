import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Core from "../common/clientSdk";
import { useNavigate } from "react-router-dom";
import CustomTable from "../components/customTable";
import {
  CustomButton,
  CustomText,
  FallBack,
  FallBackBtn,
  Image,
} from "../components";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";

function CollectionCampaignInfluencerTable(props) {
  console.log("props", props);

  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);
  const [loading, setListLoading] = useState(false);
  const [hoveredIcons, setHoveredIcons] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingUI,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const navigate = useNavigate;
  useEffect(() => {
    getCollectionInfluencerList();
  }, []);

  function getCollectionInfluencerList() {
    const params = {
      collection_id: props.collectionId,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (status) => setListLoading(status),
      params
    );
  }

  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionInfluencerList(response_data.collection_deep_data);
  }

  function getCollectionInfluencerListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    setCollectionInfluencerList([]);
    setListLoading(false);
  }

  console.log("setCollectionInfluencerList", collectionInfluencerList.length);

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const platformData = platforms_data.find(
      (item) => item.platform_id === props.platformId
    );

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 &&
          platformData &&
          platforms_data[0].platform_id != null && (
            <div>
              {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
            </div>
          )}
        <div style={{ marginTop: 7 }} className="flex-row">
          <div
            className={"platform-name"}
            onMouseEnter={() =>
              handleMouseEnter(`${influencer_id}${platformData?.platform_id}`)
            }
            onMouseLeave={() =>
              handleMouseLeave(`${influencer_id}${platformData.platform_id}`)
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platformData?.platform_id,
                  platformData?.identifier_id
                )
              )
            }
          >
            {platformData?.platform_id && (
              <Icon
                iconName={
                  hoveredIcons[`${influencer_id}${platformData?.platform_id}`]
                    ? Utils.getSocialIconHover(platformData?.platform_id)
                    : Utils.getSocialIcon(platformData?.platform_id)
                }
                height={14}
                width={14}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find(
      (item) => item.platform_id === props.platformId
    );
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);

    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };
  const tableInfluencerSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row["influencer_data"]["name"],

      cell: (data) => (
        <span style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Image
            source={
              data?.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data?.influencer_data.influencer_id,
                    data?.influencer_data.profile_picture
                  )
                : require("../resources/images/user-copy.png")
            }
            className="proposal-user-profile"
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data?.influencer_data.name}
                  className="Body-1"
                />
              </div>
            </div>
          </div>
        </span>
      ),
    },
    {
      name: "Social Presence",
      selector: (row) =>
        platFormName(row.platforms_data, row.influencer_data.influencer_id),
      // sortable: true,
    },

    {
      name: "Followers",
      selector: (row) => formatPlatformData(row?.platforms_data, "followers"),
      sortable: false,
    },
    // {
    //   name: "Avg Views",
    //   selector: (row) =>
    //     Utils.isObjectOrNullUndefinedZero(
    //       formatPlatformData(row?.platforms_data, "avg_views")
    //     )
    //       ? "-"
    //       : formatPlatformData(row?.platforms_data, "avg_views"),
    //   width: "100px",
    // },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: Utils.colorStatus(row?.influencer_data.review_status),
          }}
        >
          {row?.influencer_data.review_status
            ? row?.influencer_data.review_status
            : "NOT REVIEW"}
        </span>
      ),
      sortable: false,
    },
    // {
    //   name: "ER",
    //   selector: (row) =>
    //     Utils.isNullOrUndefinedOrZero(
    //       formatPlatformData(row.platforms_data, "engagement_rate")
    //     )
    //       ? "-"
    //       : formatPlatformData(row.platforms_data, "engagement_rate"),
    //   width: "130px",
    //   cell: (data) => (
    //     <span>
    //       <Tooltip
    //         title={
    //           <>
    //             <div>
    //               Avg Likes ={" "}
    //               {formatPlatformData(data?.platforms_data, "avg_likes")}
    //             </div>
    //             <div>
    //               Avg Comments ={" "}
    //               {formatPlatformData(data?.platforms_data, "avg_comments")}
    //             </div>
    //           </>
    //         }
    //       >
    //         {Utils.isNullOrUndefinedOrZero(
    //           Number(
    //             formatPlatformData(data?.platforms_data, "engagement_rate")
    //           )
    //         )
    //           ? "-"
    //           : Number(
    //               formatPlatformData(data?.platforms_data, "engagement_rate")
    //             ).toFixed(2) + " %"}
    //       </Tooltip>
    //     </span>
    //   ),
    // },
    {
      name: "Price",
      selector: (row) =>
        row?.influencer_data?.price ? row?.influencer_data?.price : "...",
      sortable: false,

      cell: (data) => (
        <span style={{ color: "rgb(25, 119, 242)" }} className="flex-row">
          <CustomText
            p
            text={
              data?.influencer_data?.price
                ? data?.influencer_data?.price
                : "..."
            }
            className=" m-r-6"
          />
          <div>
            <Icon iconName="EditIconCounter" />
          </div>
        </span>
      ),
    },
    {
      name: "Action",

      cell: (data) => (
        <span>
          <CustomButton
            fontSize={"font_size_large"}
            iconName="deleteNewIcon"
            borderRadius={4}
            padding={{ top: 6, right: 18, bottom: 6, left: 18 }}
            textColor={"text_color_light"}
            background={"none"}
            border={"none"}
            boxShadow={"none"}
            hoverColor={"none"}
          />
        </span>
      ),
    },
  ];

  function customTableData() {
    if (collectionInfluencerList.length !== 0) {
      return (
        <div className="custom-table">
          <CustomTable
            className="CustomTable"
            tableSchema={tableInfluencerSchema}
            tableData={collectionInfluencerList?.collection_influencer_list}
            paginationPerPage={15}
            rowPaddingTop={16}
            rowPaddingBottom={16}
            customStyles={customStyles}
            pagination
          />
        </div>
      );
    } else if (collectionInfluencerList.length == 0) {
      return (
        <div className="fall-back-center-campaign">
          <FallBackBtn
            heading={"Oops!! you don't have any influencer in this collection"}
            title={
              "You can add your shortlisted influencers in collections, so that you can share them with anyone and get feedback"
            }
            widthIcon={100}
            heightIcon={100}
          />
        </div>
      );
    } else if (!loading) {
      return (
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
            height: 20,

            alignItems: "center",
          }}
        >
          <Lottie options={defaultOptions} height={15} width={100} />
        </div>
      );
    }
  }

  return <>{customTableData()}</>;
}

export default CollectionCampaignInfluencerTable;
const customStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)",
      color: "var(--gray-gray-300)",
      fontSize: "16px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};
