import React, { useEffect, useState } from "react";
import { AssignmentList, FallBack, Image, PrimaryCta } from "../../components";
import CustomTable from "../../components/customTable";
import { toast } from "react-toastify";
import Core from "../../common/clientSdk";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../loader";
import Utils from "../../common/utils";
import { useSnackbar } from "react-simple-snackbar";
import { CustomText } from "../../components";
import Icon from "../../components/core_component/icon";
import exportToCSV from "../../common/exportToCSV";
import { InfluencerContractsShareLink } from "../../modal/influencercontractsShare";

const campaignStatusList = [
  "ALL",
  "ACTIVE",
  "COMPLETED",
  "INACTIVE",
  "IN_PROGRESS",
  "CANCELLED",
];

const limit = 10;
function AssignmentListScreen(props) {
  const [assignmentList, setAssignmentList] = useState([]);
  const [start, setStart] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [influencerContractsList, setInfluencerContractsList] = useState([]);
  const [filterLabel, setFilterLabel] = useState("");
  // const [sortBy, setSortBy] = useState(null);
  // const [sortOrder, setSortOrder] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(0);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [modalIsOpenShare, setModalIsOpenShare] = useState(false);
  const [openSnackbar] = useSnackbar();
  const navigate = useNavigate();
  const [statusColors] = useState({
    ACTIVE: "#FABC13",
    INACTIVE: "red",
    COMPLETED: "#009A29",
    IN_REVIEW: "#1977f2",
    PAYMENT_PENDING: "#ff4500",
    CENCELLED: "#ff4500",
    IN_PROGRESS: "#059142",
  });
  const sortTypes = [
    {
      Status: "ALL",
      label: "ALL",
    },
    {
      Status: "ACTIVE",
      label: "ACTIVE",
    },
    {
      Status: "COMPLETED",
      label: "COMPLETED",
    },

    {
      Status: "IN_PROGRESS",
      label: "IN PROGRESS",
    },

    {
      Status: "CANCELLED",
      label: "CANCELLED",
    },
  ];

  useEffect(() => {
    getAssignmentUser(start, updateProgress);
  }, [filterStatus]);

  const collectionData = useParams().id;
  let params = new URLSearchParams(collectionData);
  let collectionId = params.get("collection_id");
  let collection_name = params.get("collection_name");

  function openModalShare() {
    if (modalIsOpenShare == false) {
      setModalIsOpenShare(true);
    } else {
      setModalIsOpenShare(false);
    }
  }

  function closeShareModal() {
    setModalIsOpenShare(false);
  }

  function updateProgress(status) {}

  const campaignId = props.campaignId;

  function getAssignmentUser(startPage, progress_cb) {
    var params = {
      campaign_id: campaignId,
      // limit: limit,
      // start: startPage,
    };
    if (filterStatus != "ALL") {
      params.status = filterStatus;
    }
    Core.getAssignmentUser(
      getAssignmentUserSuccess,
      getAssignmentUserFailed,
      progress_cb,
      params
    );
  }

  function getAssignmentUserSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
    // if (next == 0) {
    //   setAssignmentList(response_data["assignment_list"]);
    // } else {
    //   setAssignmentList([
    //     ...assignmentList,
    //     ...response_data["assignment_list"],
    //   ]);
    // }
    setNext(response_data?.pagination_data?.next);
    setLoading(false);
  }

  function getAssignmentUserFailed(errorList) {
    openSnackbar(errorList.map((err) => err.m).join(", "));
  }

  function getGenerateShareKeyCampaignContractsList() {
    var params = {
      campaign_id: campaignId,
    };

    Core.getGenerateShareKeyCampaignContractsList(
      getGenerateShareKeyCampaignContractsListSuccess,
      getGenerateShareKeyCampaignContractsListFailed,
      () => {},
      params
    );
  }

  function getGenerateShareKeyCampaignContractsListSuccess(response_data) {}

  function getGenerateShareKeyCampaignContractsListFailed(errorList) {
    openSnackbar(errorList.map((err) => err.m).join(", "));
  }

  function navigateToAssignmentDetails(assignmentId) {
    navigate(`/contracts/${assignmentId}`);
  }

  function SortDropDown() {
    if (filterDropDown) {
      setFilterDropDown(false);
    } else {
      setFilterDropDown(true);
    }
  }

  function filterDropDownOpenClose() {
    if (filterStatus != "ALL") {
      setFilterLabel(null);
      setFilterStatus("ALL");
    } else {
      SortDropDown();
    }
  }
  // const customStyles = {
  //   rows: {
  //     style: {
  //       background: "#1C1835",
  //       fontSize: "16px",
  //       color: "#ECEBF6",
  //       paddingTop: "20px",
  //       borderBottom: ".5px solid #54545f",
  //     },
  //   },

  //   headCells: {
  //     style: {
  //       background: "#1C1835",
  //       fontSize: "15px",
  //       color: "#A6A5B4",
  //       paddingTop: "26px",
  //       paddingBottom: "26px",
  //       minWidth: "150px !important",
  //       // borderBottom: ".5px solid ##424158",
  //       border: "none !important",
  //     },
  //   },
  //   cells: {
  //     style: {
  //       borderBottom: ".5px solid #54545f",
  //       paddingBottom: "26px",
  //       minWidth: "120px !important",
  //     },
  //   },
  // };

  // let completedAssignment = 0;

  // let totalAssignment;

  // function getDataDeliverables(contents_deep_data) {
  //   totalAssignment = Object.keys(contents_deep_data).length;

  //   Object.keys(contents_deep_data).map((item) => {
  //     const { status } = contents_deep_data[item].content_data;
  //     if (status == "COMPLETED") {
  //       completedAssignment++;
  //     }

  //     return (
  //       completedAssignment +
  //       " " +
  //       "out of " +
  //       totalAssignment +
  //       " images ready to review"
  //     );
  //   });
  //   return (
  //     completedAssignment +
  //     " out of " +
  //     totalAssignment +
  //     " images ready to review"
  //   );
  // }

  const AssignmentCSV = () => {
    const data = assignmentList.map((item) => {
      let content_data;
      if (item.assignment_data.type === "social_media") {
        content_data = Utils.constructContentMediaUrl(
          item.assignment_data.assignment_id,
          Object.values(item.contents_deep_data)[0].content_data.media_id
        );
      } else {
        content_data = "";
      }

      return {
        "Contract ID": item.assignment_data.assignment_id,
        "Influencer Name": item.influencer_data.name,
        "Contract Amount": item.reward_data.value.amount,
        "Content Status": Utils.generateAssignmentSubText(item),
        "Contract Status": Utils.assignmentStatusToString(
          item.assignment_data.assignment_status
        ),
        "Contract Date": Utils.convertUnixToDate(
          item.assignment_data.date_added
        ),
        "Delivery Date": Utils.convertUnixToDate(
          item.assignment_data.delivery_date
        ),
        "Social URL": Object.values(item.contents_deep_data)[0].content_data
          .url,
        "Content URL": content_data,
      };
    });

    return (
      <div className="bid-csv-btn" onClick={() => exportToCSV(data)} style={{}}>
        <Icon iconName="downloadIcon" />
      </div>
    );
  };

  const tableSchema = [
    {
      // name: "Influencer Name",
      // selector: (row) => {
      //   console.log('row',row.influencer_data.name)
      //   return row["influencer_data"]["name"];

      //

      name: "Influencer Name",
      selector: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            fallBack={require("../../resources/images/fallback.png")}
            source={
              row.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    row.influencer_data.influencer_id,
                    row.influencer_data.profile_picture
                  )
                : require("../../resources/images/fallback.png")
            }
            className="table-profile"
          />
          <div className="influencer-table-profile">
            {" "}
            {row.influencer_data.name}
          </div>
        </div>
      ),
    },

    {
      name: "Rewards",
      selector: (row) => {
        return row["reward_data"]["value"]["amount"] == 0
          ? "_"
          : "Rs " + row["reward_data"]["value"]["amount"];
      },
    },

    {
      name: "Deliverables",
      selector: (row) => {
        return Utils.generateAssignmentSubText(row);
      },
    },

    {
      name: "Status",
      selector: (row) => {
        return Utils.assignmentStatusToString(
          row["assignment_data"]["assignment_status"]
        );
      },

      maxWidth: "195px",
      cell: (data) => (
        <span
          style={{
            color: statusColors[data.assignment_data.assignment_status],
          }}
          // onClick={(e) => {
          //   CampaignClick(data.campaign_data.campaign_id);
          //   e.stopPropagation();
          // }}
        >
          {Utils.assignmentStatusToString(
            data.assignment_data.assignment_status
          )}
        </span>
      ),
    },
  ];

  // Table CSS

  const customStyle = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  if (!props.isActive) {
    return <></>;
  }

  return (
    <div style={{ minHeight: 500 }}>
      {isLoading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <>
          <div className="bid-list-dropdown-header-container-new">
            <div className="cursor-pointer" onClick={openModalShare}>
              {" "}
              <Icon iconName="shareIcons" />
            </div>
            <div className="dropdown-button-container">
              <button
                onClick={() => filterDropDownOpenClose()}
                className="dropdown-button"
              >
                <CustomText
                  p
                  text={filterLabel ? filterLabel : "ALL"}
                  fontSize={"font_size_regular"}
                  textColor={"text_color_black"}
                  textAlign={"text_align_right"}
                />
                {filterLabel ? (
                  <div className="cursor-pointer">
                    <Image
                      source={require("../../resources/images/bid-search-cross.png")}
                    />
                  </div>
                ) : (
                  <div className="cursor-pointer">
                    <Icon iconName={"DropdownIcon"} height={18} width={18} />
                  </div>
                )}
              </button>
              {filterDropDown && (
                <div className="dropdown-list-container-contract">
                  {sortTypes.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setNext(0);
                          setFilterStatus(item.Status);
                          SortDropDown();
                          setFilterLabel(item.label);
                        }}
                        className={
                          item.Status == filterStatus
                            ? "dropdown-button-list-selected"
                            : "dropdown-button-list"
                        }
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div>
              <AssignmentCSV />
            </div>
          </div>

          <div className="custom-table-new">
            {/*      
      <InfluencerListDropdownNew
              dropdownItems={campaignStatusList}
              onItemClick={(item) => {
                setNext(0);
                setFilterStatus(item);
              }}
            /> */}

            <div className="table-contract">
              <CustomTable
                className="CustomTable"
                tableSchema={tableSchema}
                tableData={assignmentList}
                rowPaddingTop={16}
                paginationPerPage={15}
                rowPaddingBottom={16}
                customStyles={customStyle}
                onRowClicked={(item) =>
                  navigateToAssignmentDetails(
                    item.assignment_data.assignment_id
                  )
                }
                pagination
              />
            </div>
          </div>
        </>
      )}
      {}
      {modalIsOpenShare && (
        <InfluencerContractsShareLink
          closeShareModal={closeShareModal}
          campaignId={campaignId}
          // shareKey={
          //   influencerContractsList.length > 0 &&
          //   influencerContractsList[0]?.collection_data?.share_key
          // }
        />
      )}

      {/* <div className="custom-table">
    <CustomTable
      className="CustomTable"
      tableSchema={tableSchema}
      tableData={assignmentList}
      rowPaddingTop={16}
      paginationPerPage={15}
      rowPaddingBottom={16}
      customStyles={customStyle}
      onRowClicked={(item) =>
        navigateToAssignmentDetails(
          item.assignment_data.assignment_id
        )
      }
      pagination
    />
  </div> */}
    </div>
  );

  // const columns = [
  //   {
  //     name: "Campaign Name",
  //     selector: (row) => row.assignment_data.name,
  //   },
  //   {
  //     name: "Creator Name",
  //     selector: (row) => (
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <Image
  //           fallBack={require("../../resources/images/fallback.png")}
  //           source={
  //             row.influencer_data.profile_picture
  //               ? Utils.generateInfluencerDpUrl(
  //                   row.influencer_data.influencer_id,
  //                   row.influencer_data.profile_picture
  //                 )
  //               : require("../../resources/images/fallback.png")
  //           }
  //           className="table-profile"
  //         />
  //         <div className="influencer-table-profile">
  //           {" "}
  //           {row.influencer_data.name}
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     name: "Amount",
  //     selector: (row) => row.reward_data.value.amount,
  //   },
  //   {
  //     name: "Deliverables",
  //     selector: (row) => getDataDeliverables(row.contents_deep_data),
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) => row.assignment_data.assignment_status,
  //     conditionalCellStyles: [
  //       {
  //         when: (row) => row.assignment_data.assignment_status === "ACTIVE",
  //         style: Style.text_active_color,
  //       },
  //       {
  //         when: (row) =>
  //           row.assignment_data.assignment_status === "PAYMENT_PENDING",
  //         style: Style.text_completed_color,
  //       },
  //       {
  //         when: (row) => row.assignment_data.assignment_status === "COMPLETED",
  //         style: Style.text_completed_color,
  //       },
  //       {
  //         when: (row) => row.assignment_data.assignment_status === "CANCELLED",

  //         style: Style.text_pending_color,
  //       },
  //       {
  //         when: (row) =>
  //           row.assignment_data.assignment_status === "IN_PROGRESS",
  //         style: Style.text_active_color,
  //       },
  //     ],
  //   },
  // ];

  // return (
  //   <div style={{ minHeight: 500 }}>
  //     {isLoading ? (
  //       <div className="fall-back-center-campaign">
  //         <Loader />
  //       </div>
  //     ) : (
  //       <>
  //         {assignmentList && assignmentList.length !== 0 ? (
  //           <InfiniteScroll
  //             dataLength={assignmentList.length}
  //             next={() => getAssignmentUser(next, updateProgress)}
  //             hasMore={next !== null}
  //             loader={<h4>loading...</h4>}>
  //             <div className="contract-table-list">
  //               {assignmentList.map((row) => (
  //                 <AssignmentList
  //                   className="campaign-list-contract"
  //                   onClick={() =>
  //                     navigateToAssignmentDetails(
  //                       row.assignment_data.assignment_id
  //                     )
  //                   }
  //                   source={
  //                     row.influencer_data.profile_picture
  //                       ? Utils.generateInfluencerDpUrl(
  //                           row.influencer_data.influencer_id,
  //                           row.influencer_data.profile_picture
  //                         )
  //                       : require("../../resources/images/fallback.png")
  //                   }
  //                   influencerName={row.influencer_data.name}
  //                   rewardText={Utils.formatRewardText(row.reward_data)}
  //                   deliverablesText={Utils.generateAssignmentSubText(row)}
  //                   status={Utils.assignmentStatusToString(
  //                     row.assignment_data.assignment_status
  //                   )}
  //                 />
  //               ))}
  //             </div>
  //           </InfiniteScroll>
  //         ) : (
  //           <div className="fall-back-center-campaign">
  //             <FallBack
  //               heading={"No Contracts Available"}
  //               title={"Explore more and short out some items"}
  //               // subTitle={"elit, sed do eiusmod tempor incididunt. ipasum"}
  //             />
  //           </div>
  //         )}
  //       </>
  //     )}
  //   </div>
  // );
}

export default AssignmentListScreen;
